import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

/**
 * Classes to apply:
 *
 * icon - for reduce paddings when inserting icon into button;
 *
 * flex - for remove paddings and add flex: 1;
 *
 * outlined - for change button's appearance;
 *
 * full-width - for adding width: 100%;
 */
@Directive({
  selector: '[appButton]',
  standalone: true,
})
export class BtnDirective implements OnChanges {
  @Input() public set disabled(v: boolean) {
    this._disabled = v;

    if (this._disabled) {
      this.renderer.setAttribute(
        this.elementRef.nativeElement,
        'disabled',
        'true'
      );
    } else {
      this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
    }
  }

  @Input() public loading = false;

  private spinner: HTMLDivElement;

  private _disabled = false;

  constructor(
    private elementRef: ElementRef<HTMLButtonElement>,
    private renderer: Renderer2
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['loading']?.firstChange) {
      this.createSpinner();
    }

    if (changes['loading']?.currentValue) {
      this.renderer.insertBefore(
        this.elementRef.nativeElement,
        this.spinner,
        this.elementRef.nativeElement.firstChild
      );
      this.renderer.addClass(this.elementRef.nativeElement, 'icon');
      this.renderer.setAttribute(
        this.elementRef.nativeElement,
        'disabled',
        'true'
      );
    } else {
      if (this.spinner) {
        this.renderer.removeChild(this.elementRef.nativeElement, this.spinner);
        this.renderer.removeClass(this.elementRef.nativeElement, 'icon');
      }

      if (!this._disabled) {
        this.renderer.removeAttribute(
          this.elementRef.nativeElement,
          'disabled'
        );
      }
    }
  }

  private createSpinner(): void {
    this.spinner = this.renderer.createElement('div');
    this.renderer.addClass(this.spinner, 'loading-spinner');
  }
}
